import React, { FC } from "react";
import get from "lodash/get";
import { useStore, useDispatch, useTranslations } from "../../store/";
import { IUseCenter, Metric } from "../../interfaces";
import { ReactComponent as Home } from "../../media/home.svg";
import { ReactComponent as Business } from "../../media/business.svg";
import { ReactComponent as MarkerHeating } from "../../media/empty_marker.svg";
import { ReactComponent as MarkerCooling } from "../../media/empty_marker_cool.svg";
import {
	Button,
	ButtonGroup,
	FormControlLabel,
	FormGroup,
	Radio,
	RadioGroup,
} from "@mui/material";
import {
	SET_COOLING_FILTER,
	SET_HOME_FILTER,
	SET_BUSINESS_FILTER,
	SET_METRIC,
	SET_DISTANCE,
	SET_HEATING_FILTER,
	UNSET_FILTERS,
} from "../../actions/types";
import FilterCheck from "../form/FilterCheck";
import { Replay, FilterAlt } from "@mui/icons-material";

type T = {
	useCenter: IUseCenter;
	toggleFiltersOpen: () => void;
};

const Filter2: FC<T> = ({ useCenter, toggleFiltersOpen }) => {
	const DISTANCES = [10, 20, 30, 40, 50];
	const state = useStore();

	const dispatch = useDispatch();
	const { general: translationGeneral } = useTranslations();

	const StyledRadio = (
		<Radio
			sx={{
				color: "black",
				"&.Mui-checked": {
					color: "black",
				},
			}}
			color="primary"
		/>
	);

	return (
		<div style={{ background: "white", marginBottom: "1em" }}>
			<div style={{ marginBottom: "1em" }}>
				{/* Distance */}
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<h2 style={{ flex: 1 }}>Distance</h2>
					<RadioGroup
						row
						onChange={(e) =>
							dispatch({
								type: SET_METRIC,
								payload: { metric: e.target.value },
							})
						}
						aria-label="position"
						name="type"
						defaultValue={Metric.KM}
						className="mt-2 map-search-box-form-distance"
					>
						<FormControlLabel
							value={Metric.KM}
							checked={state.filter.metric === Metric.KM}
							control={StyledRadio}
							label={get(translationGeneral, "km")}
							labelPlacement="start"
						/>
						{state.activeLocale && state.activeLocale.slug.current === "uk" && (
							<FormControlLabel
								value={Metric.MILES}
								checked={state.filter.metric === Metric.MILES}
								control={StyledRadio}
								label={get(translationGeneral, "miles")}
								// size={"small"}
								labelPlacement="start"
							/>
						)}
					</RadioGroup>
				</div>
				{/* Distance BTN group */}
				<div
					style={{
						position: "relative",
						textAlign: "center",
					}}
				>
					<ButtonGroup aria-label="outlined primary button group">
						{DISTANCES.map((distance) => {
							const isSelected = state.filter.distance === distance;
							return (
								<Button
									sx={{
										borderRadius: 28,
										borderColor: "black",
										padding: "10px 20px",
										"&:hover": {
											// color: isSelected ? "b" : "#eee",
											background: isSelected ? "black" : "#eee",
											borderColor: "black",
										},
										background: isSelected ? "black" : "inherit",
										color: isSelected ? "white" : "black",
									}}
									key={distance}
									onClick={() =>
										dispatch({
											type: SET_DISTANCE,
											payload: { distance },
										})
									}
								>
									{distance}
								</Button>
							);
						})}
					</ButtonGroup>
				</div>

				{/* Product type */}
				<h2>{get(translationGeneral, "productType")}</h2>
				<FormGroup>
					<FilterCheck
						value={state.filter.heating}
						label={get(translationGeneral, "heating")}
						icon={<MarkerHeating />}
						onChange={(checked) =>
							dispatch({
								type: SET_HEATING_FILTER,
								payload: checked,
							})
						}
						// checked={state.filter.heating}
					/>
					<FilterCheck
						value={state.filter.cooling}
						icon={<MarkerCooling />}
						label={get(translationGeneral, "cooling")}
						onChange={(checked) =>
							dispatch({
								type: SET_COOLING_FILTER,
								payload: checked,
							})
						}
						// checked={state.filter.cooling}
					/>
				</FormGroup>

				{/* Installer type */}
				<h2>{get(translationGeneral, "installerType")}</h2>
				<FormGroup>
					<FilterCheck
						value={state.filter.home}
						label={"home"}
						icon={<Home />}
						onChange={(checked) =>
							dispatch({
								type: SET_HOME_FILTER,
								payload: checked,
							})
						}
					/>
					<FilterCheck
						value={state.filter.business}
						icon={<Business />}
						label={"business"}
						onChange={(checked) => {
							dispatch({
								type: SET_BUSINESS_FILTER,
								payload: checked,
							});
						}}
					/>
				</FormGroup>
			</div>

			<div style={{ display: "flex", gap: "5px", flexFlow: "row wrap" }}>
				<button
					style={{ flexGrow: 1 }}
					onClick={() =>
						dispatch({
							type: UNSET_FILTERS,
						})
					}
					className="btn-ss"
				>
					<span className="icon">
						<Replay />
					</span>
					<div>{get(translationGeneral, "reset", "Reset")}</div>
				</button>
				<button
					onClick={toggleFiltersOpen}
					style={{ flexGrow: 1 }}
					className="btn-ss black-full"
				>
					<span className="icon">
						<FilterAlt />
					</span>
					<span>
						{" "}
						{get(translationGeneral, "closeFilters", "Close filters")}
					</span>
				</button>
			</div>
		</div>
	);
};

export default Filter2;
