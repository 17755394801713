import React, { FC } from "react";
import Autocomplete from "react-google-autocomplete";
import { useStore, useTranslations, useDispatch } from "../../store/";
import { useLocation } from "react-router-dom";
import get from "lodash/get";
import { setActiveLocaleFromLocale } from "../../actions";
import styled from "styled-components";
import { ILocale, IUseCenter } from "../../interfaces";
import {
	FilterAlt,
	MyLocation,
	Search,
	FilterAltOutlined,
} from "@mui/icons-material";

import { SET_TARGET_LOCATION } from "../../actions/types";

type T = {
	useCenter: IUseCenter;
	toggleFiltersOpen: () => void;
	filtersOpen: boolean;
};

const FilterSearch: FC<T> = ({ useCenter, toggleFiltersOpen, filtersOpen }) => {
	const dispatch = useDispatch();
	const { general: translationGeneral } = useTranslations();

	const state = useStore();
	const location = useLocation();
	const country = location.pathname.replace("/", "");
	const [key, setKey] = React.useState("");

	const [, setCenter] = useCenter;

	React.useEffect(() => {
		if (!state.filter.target) setKey(key + 1);
	}, [state.filter.target, setKey]);

	const onLocate = () => {
		navigator.geolocation.getCurrentPosition((position) => {
			console.log("Latitude is :", position.coords.latitude);
			console.log("Longitude is :", position.coords.longitude);
			setCenter({
				lat: position.coords.latitude,
				lng: position.coords.longitude,
			});
		});
	};

	return (
		<Wrapper>
			<Input>
				<Autocomplete
					apiKey={"AIzaSyC9b1AB2O42mz5tRhb3grIzdRM5XCVnezU&v=3"}
					style={{
						border: "none",
						padding: "1em",
						flex: "1",
					}}
					key={key}
					options={{
						types: ["(regions)"],
						componentRestrictions: { country },
					}}
					placeholder={get(
						translationGeneral,
						"enterALocation",
						"Enter a location"
					)}
					onPlaceSelected={(place: any) => {
						if (place && place.geometry) {
							const country = place.address_components.find((comp: any) =>
								comp.types.includes("country")
							);

							if (country) {
								const countryAbbrevs = [country.short_name.toLowerCase()];
								if (country.short_name.toLowerCase() === "gb")
									countryAbbrevs.push("uk");

								const loc = state.locales.find(
									(locale: ILocale) =>
										country.short_name &&
										countryAbbrevs.includes(locale.slug.current)
								);

								loc && setActiveLocaleFromLocale(loc);
							}

							const coords = {
								lat: place.geometry.location.lat() as number,
								lng: place.geometry.location.lng() as number,
							};
							setCenter(coords);
							dispatch({
								type: SET_TARGET_LOCATION,
								payload: { target: coords },
							});
						}
					}}
					types={[]}
					// types= {['address']}
				/>
			</Input>
			<Icons>
				{/* <Icon> */}
				<div className="iconContainer">
					<Search />
				</div>
				<div className="iconContainer" onClick={() => onLocate()}>
					<MyLocation sx={{ fontSize: 19 }} />
				</div>
				<div className="iconContainer filterIcon" onClick={toggleFiltersOpen}>
					<div className={`triangle ${filtersOpen ? "active" : ""}`} />
					{filtersOpen ? <FilterAlt /> : <FilterAltOutlined />}
				</div>
			</Icons>
			{/* </Icon> */}
		</Wrapper>
	);
};
const BREAKPOINT = 640;

const Wrapper = styled.div`
	border-top: 2px solid #eee;
	border-bottom: 2px solid #eee;
	display: flex;
	cursor: pointer;

	align-items: stretch;
	/* justify-content: end; */
	@media only screen and (max-width: ${BREAKPOINT}px) {
		margin: 1em;
		border: 2px solid #eee;
	}
`;
const Icons = styled.div`
	display: flex;
	gap: 4px;

	console.log('look here 	gap: 8px;', 	gap: 8px;);
	
	align-items: stretch;
	cursor: pointer;
	svg {
		margin: auto;
	}

	.iconContainer {
		padding: 0 4px;
		display: flex;
	}

	.filterIcon {
		border-left: 2px solid #eee;
		
		position: relative;

		.triangle {
			position: absolute;
			position: absolute;
			bottom: 0;
			overflow: hidden;
			inset: 0 0 -2px 0;

			&.active::after {
				transform: translateY(8px) rotate(45deg);
			}
			&::after {
				content: "";
				transition: all 0.2s ease;
				display: block;
				position: absolute;
				left: 1px;
				right: 1px;
				margin: auto;
				bottom: 0;
				background: #fff;
				width: 10px;
				height: 10px;
				border: 2px solid #eee;
				transform: translateY(17px) rotate(45deg);
			}
		}
	}
`;
const Input = styled.div`
	flex: 1;

	input {
		width: 100%;
	}
	@media only screen and (max-width: ${BREAKPOINT}px) {
		/* border: 2px solid #eee; */
	}
`;

export default FilterSearch;
