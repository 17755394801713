import React, { FC } from "react";
import { useStore, useTranslations, useDispatch } from "../../store/";
import { Close } from "@mui/icons-material";
import { Metric } from "../../interfaces";
import get from "lodash/get";
import {
	SET_DISTANCE,
	UNSET_FILTERS,
	UNSET_FILTER_ITEM,
} from "../../actions/types";
import { Replay } from "@mui/icons-material";

type T = any;

const FilterBadges: FC<T> = (props) => {
	const dispatch = useDispatch();

	const { filter } = useStore();

	const items = ["home", "business", "cooling", "heating", "distance"];
	const { general: translationGeneral } = useTranslations();

	const texts = items.flatMap((item) => {
		if (!(filter as any)[item]) return [];
		if (item === "distance") {
			if (!filter.target) return [];
			return {
				text: `${filter.distance} ${get(
					translationGeneral,
					filter.metric === Metric.KM ? "km" : "miles"
				)}`,
				handler: () =>
					dispatch({
						type: SET_DISTANCE,
						payload: { distance: 0 },
					}),
			};
		}
		return {
			text: `${item}`,
			handler: () =>
				dispatch({
					type: UNSET_FILTER_ITEM,
					payload: item,
				}),
		};
	});

	return texts.length ? (
		<div
			style={{
				display: "flex",
				gap: "0.5em",
				flexFlow: "row wrap",
				padding: "0.5em 0",
			}}
		>
			{texts.map((el) => {
				return (
					<button
						key={el.text}
						onClick={() => el.handler()}
						style={{
							padding: "0.5em 0.75em",
							backgroundColor: "#F5F5F5",
							border: "none",
							letterSpacing: "0.1em",
							borderRadius: "100px",
							display: "flex",
							alignItems: "center",
							gap: "5px",
							fontSize: "12px",
						}}
					>
						{String(el.text).toUpperCase()}

						<Close sx={{ fontSize: 12 }} />
					</button>
				);
			})}
			{texts.length ? (
				<button
					className="btn-text"
					onClick={() =>
						dispatch({
							type: UNSET_FILTERS,
						})
					}
				>
					<Replay sx={{ fontSize: 16, marginRight: "0.25em" }} />{" "}
					{get(translationGeneral, "resetFilters", "Reset")}
				</button>
			) : null}
		</div>
	) : null;
};

export default FilterBadges;
