import React, { FC, useState } from "react";
import {
	useFloating,
	autoUpdate,
	offset,
	flip,
	shift,
	useHover,
	useFocus,
	useDismiss,
	useRole,
	useInteractions,
	FloatingPortal,
} from "@floating-ui/react";
import "react-toastify/dist/ReactToastify.css";
import { InfoOutlined } from "@mui/icons-material";
import get from "lodash/get";
import { useStore } from "../store";

import Fade from "./Fade";
type T = any;

function Tooltip() {
	const state = useStore();

	const [isOpen, setIsOpen] = useState(false);

	const { refs, floatingStyles, context } = useFloating({
		open: isOpen,
		onOpenChange: setIsOpen,
		placement: "right",
		// Make sure the tooltip stays on the screen
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(5),
			flip({
				fallbackAxisSideDirection: "start",
			}),
			shift(),
		],
	});

	// Event listeners to change the open state
	const hover = useHover(context, { move: false });
	const focus = useFocus(context);
	const dismiss = useDismiss(context);
	// Role props for screen readers
	const role = useRole(context, { role: "tooltip" });

	// Merge all the interactions into prop getters
	const { getReferenceProps, getFloatingProps } = useInteractions([
		hover,
		focus,
		dismiss,
		role,
	]);

	return (
		<>
			<div style={{ position: "relative", zIndex: 1 }}>
				<div ref={refs.setReference} {...getReferenceProps()}>
					<InfoOutlined />
				</div>

				<FloatingPortal>
					<Fade in={isOpen}>
						{isOpen && (
							<div
								className="Tooltip"
								ref={refs.setFloating}
								style={{ ...floatingStyles, zIndex: 1 }}
								{...getFloatingProps()}
							>
								<div
									style={{
										backgroundColor: "white",
										borderRadius: "1em",
										padding: "1em",
										zIndex: 1,
										maxWidth: "250px",
										boxShadow: "rgba(0, 0, 0, 0.31) 0px 0px 30px -10px",
									}}
								>
									<p>{get(state, "copy.disclaimer")}</p>
								</div>
							</div>
						)}
					</Fade>
				</FloatingPortal>
			</div>
		</>
	);
}

export default Tooltip;
