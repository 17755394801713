import React, { FC } from "react";
import { ILocator } from "../../interfaces";
import { useSelector } from "react-redux";
import { State } from "../../reducers";
import get from "lodash/get";
type T = {
	locator: ILocator;
};

// The logic for determining the right string is a bit weird, in the database we safe "for home", or 'for ... and ...'.
// The new design has diferent terminology, the logic below makes sure the new design works with the old data model.

const TypeSummary: FC<T> = ({ locator }) => {
	const { general: translationGeneral } = useSelector(
		(state: State) => state && state.translations
	);
	const Heating = () => (
		<span style={{ color: "#D40000" }}>
			{get(translationGeneral, "heating", "heating")}
		</span>
	);
	const Cooling = () => (
		<span style={{ color: "#04C4EE" }}>
			{get(translationGeneral, "cooling", "cooling")}
		</span>
	);
	const home = get(translationGeneral, "home", "home");
	const business = get(translationGeneral, "business", "business");

	const mappingInstaller = [
		["for home", home],
		["for business", business],
		["for home & business", `${home} & ${business}`],
	];
	const installerTypeSummary =
		mappingInstaller.find(
			([type]) => type === locator.installerType?.toLowerCase()
		)?.[1] || null;

	const mappingProduct: [string, React.ReactElement<any>][] = [
		["heating", <Heating />],
		["cooling", <Cooling />],
		[
			"cooling & heating",

			<span>
				<Heating /> & <Cooling />
			</span>,
		],
	];
	const ProductSummary = () => {
		if (!locator.productType) return null;
		const item = mappingProduct.find(
			([type]) => type === locator.productType.toLowerCase()
		);
		return item ? item[1] : null;
	};

	return (
		<div
			className="small"
			style={{
				display: "flex",
				alignItems: "center",
				gap: "5px",
				textTransform: "uppercase",
			}}
		>
			<span>{installerTypeSummary}</span>
			{installerTypeSummary && (
				<span
					style={{ fontSize: "0.5em", verticalAlign: "bottom", opacity: 0.3 }}
				>
					{" "}
					●{" "}
				</span>
			)}

			{ProductSummary() || null}
		</div>
	);
};

export default TypeSummary;
