export const SET_DATA = "SET_DATA";
export const SET_ACTIVE_LOCALE = "SET_ACTIVE_LOCALE";
export const SET_FILTERED_LOCATORS = "SET_FILTERED_LOCATORS";
export const SET_TARGET_LOCATION = "SET_TARGET_LOCATION";
export const SET_DISTANCE = "SET_DISTANCE";
export const SET_METRIC = "SET_METRIC";
export const UNSET_FILTER_ITEM = "UNSET_FILTER_ITEM";
export const SET_INSTALLER_TYPE = "SET_INSTALLER_TYPE";
export const SET_COOLING_FILTER = "SET_COOLING_FILTER";
export const SET_HEATING_FILTER = "SET_HEATING_FILTER";
export const SET_HOME_FILTER = "SET_HOME_FILTER";
export const SET_BUSINESS_FILTER = "SET_BUSINESS_FILTER";
export const SET_PRODUCT_TYPE = "SET_PRODUCT_TYPE";
export const SET_ACTIVE_LOCATOR = "SET_ACTIVE_LOCATOR";
export const SET_READY = "SET_READY";
export const SET_TRANSLATION_AND_COPY = "SET_TRANSLATION_AND_COPY";
export const SET_BUSY = "SET_BUSY";
export const SET_CONTACT_MODAL = "SET_CONTACT_MODAL";
export const SUBMIT_FORM_PENDING = "SUBMIT_FORM_PENDING";
export const SUBMIT_FORM_SUCCESS = "SUBMIT_FORM_SUCCESS";
export const SUBMIT_FORM_ERROR = "SUBMIT_FORM_ERROR";
export const FORM_CLEAR_STATE = "FORM_CLEAR_STATE";
export const SET_EMAIL_TEMPLATES = "SET_EMAIL_TEMPLATES";
export const SET_HAS_PRIVACY_POLICY = "SET_HAS_PRIVACY_POLICY";
export const UNSET_FILTERS = "UNSET_FILTERS";
