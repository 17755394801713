import React, { FC, useRef, useEffect, useState } from "react";
import get from "lodash/get";
import FilterCard2 from "../filter/FilterCard2";
import { useStore } from "../../store/";

import { useSelector } from "react-redux";

import { State } from "../../reducers";
import { ILocator } from "../../interfaces";
import { Skeleton } from "@mui/material";
import styled from "styled-components";
import { useWindowSize } from "../../utils";
type T = {
	map: any;
	setContactLocator: any;
	listContainerRef: any;
};

const Installers: FC<T> = ({ map, setContactLocator, listContainerRef }) => {
	const { general: translationGeneral } = useSelector(
		(state: State) => state && state.translations
	);
	const refs = useRef<any>({});
	const containerRef = useRef<HTMLDivElement>(null);
	const state = useStore();

	const activeLocatorId = state.activeLocator?._id;

	// Height setter

	const { height: windowHeight, width: windowWidth } = useWindowSize();
	const [scrollBoxHeight, setScrollBoxHeight] = useState<string>("100svh");
	useEffect(() => {
		const top = containerRef.current?.getBoundingClientRect().top;
		if (!top) return;
		if (windowWidth < 640) return;
		const newHeight = windowWidth < 640 ? "40vh" : `${windowHeight - top}px`;
		setScrollBoxHeight(newHeight);
	}, [windowHeight, windowWidth]);

	useEffect(() => {
		if (!activeLocatorId) return;
		const locatorRef = refs.current[activeLocatorId];
		if (!locatorRef) return;

		if (windowWidth < 640) {
			listContainerRef.current.scrollTo({
				top: locatorRef.offsetHeight,
				behavior: "smooth",
			});
		}

		containerRef.current?.scrollTo({
			top: locatorRef.offsetTop - 64,
			behavior: "smooth",
		});
	}, [activeLocatorId]);

	return (
		<div
			ref={containerRef}
			style={{ height: scrollBoxHeight, overflow: "scroll" }}
		>
			{state.busy || !state.ready ? (
				<>
					{Array.from(Array(10).keys()).map((_, i) => (
						<SkeletonWrapper key={i}>
							<div>
								<Skeleton variant="circular" width={40} height={40} />
							</div>
							<div>
								<Skeleton variant="text" width={250} />
								<Skeleton variant="text" width={100} />
								<Skeleton variant="text" width={120} />
								<Skeleton variant="text" width={140} />
								<Skeleton variant="text" width={110} />
							</div>
						</SkeletonWrapper>
					))}
				</>
			) : (
				<div>
					{state.filteredLocators && state.filteredLocators.length ? (
						state.filteredLocators.map((locator: ILocator, i: number) => (
							<div
								key={locator._id}
								ref={(ref) => (refs.current[locator._id] = ref)}
							>
								<FilterCard2
									loading={true}
									mapRef={map}
									locator={locator}
									setContactLocator={setContactLocator}
								/>
							</div>
						))
					) : (
						<div style={{ padding: "1em", textAlign: "center" }}>
							{get(
								translationGeneral,
								"noInstallersFound",
								"No installers found. Please broaden your search criteria."
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

const SkeletonWrapper = styled.div`
	display: flex;
	gap: 15px;
	padding: 1em 0.5em;
	span {
		margin-bottom: 5px;
	}
`;

export default Installers;
