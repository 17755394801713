export interface ILocale {
	readonly title: string;
	readonly slug: {
		current: string;
	};
	readonly coordinates: ICoordinates;
	readonly translations: any;
	readonly translationsForm: any;
	readonly copy: any;
	readonly userTemplate: any;
	readonly installerTemplate: any;
}

export interface ILocator {
	readonly _id: string;
	readonly title: string;
	readonly type: string;
	readonly installerType: string;
	readonly productType: string;
	readonly location: {
		coordinates: ICoordinates;
	};
	distanceToTarget: number;
}

export interface IBusy {
	value: boolean;
	reason?: string;
}

export enum InstallerType {
	HOME_AND_BUSINESS = "For home & business",
	HOME = "For home",
	BUSINESS = "For business",
}

export enum ProductType {
	COOLING_AND_HEATING = "Cooling & Heating",
	COOLING = "Cooling",
	HEATING = "Heating",
}

export enum Metric {
	KM = "KILOMETERS",
	MILES = "MILES",
}

export interface ICoordinates {
	lat: number;
	lng: number;
}

export type IUseCenter = [
	{
		lat: number;
		lng: number;
	},
	React.Dispatch<
		React.SetStateAction<{
			lat: number;
			lng: number;
		}>
	>
];
export interface IFilter {
	installerType: InstallerType;
	cooling: boolean;
	heating: boolean;
	business: boolean;
	home: boolean;
	productType: ProductType;
	distance: number;
	update: boolean;
	metric: Metric;
	target?: ICoordinates;
}

export enum InstallerIcon {
	HOME_AND_BUSINESS = require("../both.svg"),
	HOME = require("../home.svg"),
	BUSINESS = require("../biz.svg"),
}

export const MarkerIconMapper = {
	[InstallerType.HOME_AND_BUSINESS]: InstallerIcon.HOME_AND_BUSINESS,
	[InstallerType.HOME]: InstallerIcon.HOME,
	[InstallerType.BUSINESS]: InstallerIcon.BUSINESS,
};
