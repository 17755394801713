import React from "react";
// import { GoogleMap } from "react-google-maps";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import pick from "lodash/pick";
import Icon from "../Icon";
import { Metric } from "../../interfaces";
import { State } from "../../reducers";
import { SET_ACTIVE_LOCATOR } from "../../actions/types";
import TypeSummary from "../installers/TypeSummary";

import styled from "styled-components";
const FilterCard2: React.FC<any> = ({ mapRef, locator, setContactLocator }) => {
	const { filter, hasPolicy, activeLocator } = useSelector(
		(state: State) => state
	);
	const { general: translationsGeneral } = useSelector(
		(state: State) => state && state.translations
	);
	const dispatch = useDispatch();

	const distanceStr = () => {
		const n =
			filter.metric === Metric.MILES
				? Math.round((locator.distanceToTarget / 1.609344) * 100) / 100
				: Math.round(locator.distanceToTarget * 100) / 100;
		if (isNaN(n)) return "";

		return `${n} ${filter.metric === Metric.MILES ? "miles" : "km"}`;
	};

	const goToLocator = () => {
		if (locator && mapRef && mapRef.current) {
			mapRef.current.panTo(pick(locator.location.coordinates, "lat", "lng"));
		}

		dispatch({ type: SET_ACTIVE_LOCATOR, payload: { locator } });
	};

	return (
		<Wrapper
			className={activeLocator?._id === locator._id ? "active" : ""}
			onClick={() => goToLocator()}
		>
			<Icon
				productType={locator.productType}
				installerType={locator.installerType}
			/>
			<div style={{ flex: 1 }}>
				<div
					style={{
						// display: "flex",
						alignItems: "center",
						gap: "5px",
						fontSize: "0.8em",
						fontWeight: 400,
						lineHeight: "115.02%",
						letterSpacing: "0.1em",
						whiteSpace: "nowrap",
						marginBottom: "0.75em",
						maxWidth: "95%",
						// overflow: "hidden",
					}}
				>
					<TopLine>
						<div style={{ flex: 1 }}>
							<TypeSummaryWrapper>
								<TypeSummary locator={locator} />
							</TypeSummaryWrapper>
						</div>
						<DistanceMobile>{distanceStr()}</DistanceMobile>
					</TopLine>
				</div>

				<Details>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							flexFlow: "row wrap",
						}}
					>
						<div
							style={{
								maxWidth: "250px",
								marginBottom: "1em",
								lineHeight: "17.5px",
							}}
						>
							<p style={{ fontWeight: "bold" }}>{locator.title}</p>
							<p>{get(locator, "location.street")}</p>
							{(get(locator, "location.zip") ||
								get(locator, "location.city")) && (
								<p>
									{get(locator, "location.zip")} {get(locator, "location.city")}
								</p>
							)}
							<a href="tel:{telephone}">{get(locator, "contact.phone")}</a>
						</div>
						<div className="mobile-hide">{distanceStr()}</div>
					</div>

					{hasPolicy && (
						<div>
							<button
								className="btn-ss"
								onClick={() => setContactLocator(locator)}
							>
								{get(translationsGeneral, "contact", "Contact")}
							</button>
						</div>
					)}
				</Details>
			</div>
		</Wrapper>
	);
};

export default FilterCard2;

const Wrapper = styled.div`
	display: flex;
	gap: 15px;
	padding: 1em;
	border-bottom: 2px solid #eee;
	cursor: pointer;
	transition: all 0.125s ease;

	&.active {
		@media only screen and (min-width: 641px) {
			background-color: #d1ecff;
		}
	}

	&:hover {
		background-color: #eee;
	}
`;

const TopLine = styled.div`
	display: flex;

	/* align-items: center; */
`;

const DistanceMobile = styled.div`
	/* align-items: center;
	justify-content: center;
	display: flex; */
	/* flex: 1; */

	@media only screen and (min-width: 640px) {
		display: none;
	}
`;

const Details = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;
	@media only screen and (max-width: 640px) {
		align-items: center;
		flex: 1;
		flex-flow: row;
		gap: 5px;
		justify-content: space-between;
	}
`;

const TypeSummaryWrapper = styled.div`
	/* display: flex; */
	gap: 0.5em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 300px;
`;
