/// @ts-nocheck
import { Action, Reducer } from "redux";

import {
	ILocale,
	ILocator,
	IFilter,
	InstallerType,
	Metric,
	ProductType,
} from "../interfaces";
import {
	SET_DATA,
	SET_ACTIVE_LOCALE,
	SET_FILTERED_LOCATORS,
	SET_TARGET_LOCATION,
	SET_DISTANCE,
	SET_METRIC,
	SET_INSTALLER_TYPE,
	UNSET_FILTER_ITEM,
	SET_PRODUCT_TYPE,
	SET_ACTIVE_LOCATOR,
	SET_READY,
	UNSET_FILTERS,
	SET_TRANSLATION_AND_COPY,
	SET_BUSY,
	SET_CONTACT_MODAL,
	SUBMIT_FORM_PENDING,
	SUBMIT_FORM_SUCCESS,
	SUBMIT_FORM_ERROR,
	FORM_CLEAR_STATE,
	SET_EMAIL_TEMPLATES,
	SET_HAS_PRIVACY_POLICY,
	SET_COOLING_FILTER,
	SET_HEATING_FILTER,
	SET_HOME_FILTER,
	SET_BUSINESS_FILTER,
} from "../actions/types";
import { createTrue } from "typescript";

export interface State {
	ready: boolean;
	busy: boolean;
	locales: ILocale[];
	activeLocale: ILocale | null;
	locators: ILocator[];
	filteredLocators: ILocator[];
	activeLocator: ILocator | null;
	filter: IFilter;
	targetNeedCalculation: boolean;
	translations: any;
	copy: any;
	contactLocator: any;
	emailTemplates: any;
	form: any;
	hasPolicy: boolean;
}

export const initialState: State = {
	ready: false,
	busy: false,
	locales: [],
	activeLocale: null,
	locators: [],
	filteredLocators: [],
	activeLocator: null,
	filter: {
		installerType: InstallerType.HOME_AND_BUSINESS,
		// productType: ProductType.COOLING_AND_HEATING,
		home: false,
		business: false,
		distance: 10,
		metric: Metric.KM,
		target: undefined,
		update: true,
	},
	targetNeedCalculation: false,
	translations: {},
	copy: {},
	emailTemplates: {},
	contactLocator: null,
	form: {
		busy: false,
		success: false,
	},
	hasPolicy: false,
};

export interface DispatchAction extends Action {
	payload: Partial<State>;
}

const getProductType = (filter: { cooling: boolean; heating: boolean }) => {
	if (
		(filter.cooling && filter.heating) ||
		(!filter.cooling && !filter.heating)
	)
		return ProductType.COOLING_AND_HEATING;

	if (filter.cooling) return ProductType.COOLING;
	if (filter.heating) return ProductType.HEATING;
};

const getInstallerType = (filter: { home: boolean; business: boolean }) => {
	if ((filter.home && filter.business) || (!filter.home && !filter.business))
		return InstallerType.HOME_AND_BUSINESS;

	if (filter.home) return InstallerType.HOME;
	if (filter.heating) return InstallerType.BUSINESS;
};

export const rootReducer: Reducer<State, DispatchAction> = (
	state = initialState,
	{ type, payload }
) => {
	switch (type) {
		case SET_READY: {
			return { ...state, ready: payload.ready };
		}

		case SET_TRANSLATION_AND_COPY: {
			return {
				...state,
				translations: payload.translations,
				copy: payload.copy,
			};
		}

		case SET_DATA: {
			return { ...state, locales: payload.locales, locators: payload.locators };
		}

		case SET_ACTIVE_LOCALE: {
			return {
				...state,
				activeLocale: payload.activeLocale,
				targetNeedCalculation: true,
			};
		}

		case SET_FILTERED_LOCATORS: {
			return {
				...state,
				targetNeedCalculation: false,
				filteredLocators: payload.filteredLocators,
				busy: false,
			};
		}

		case SET_TARGET_LOCATION: {
			return {
				...state,
				targetNeedCalculation: true,
				filter: { ...state.filter, target: payload.target, update: true },
			};
		}

		case SET_DISTANCE: {
			return {
				...state,
				filter: { ...state.filter, distance: payload.distance, update: true },
			};
		}

		case SET_METRIC: {
			return {
				...state,
				filter: {
					...state.filter,
					metric: payload.metric,
					update: typeof payload.update !== "undefined" ? payload.update : true,
				},
			};
		}
		case UNSET_FILTERS: {
			return {
				...state,
				filter: initialState.filter,
			};
		}

		case UNSET_FILTER_ITEM: {
			return {
				...state,
				filter: {
					...state.filter,
					[payload]: false,
				},
			};
		}
		case SET_INSTALLER_TYPE: {
			return {
				...state,
				filter: {
					...state.filter,
					installerType: payload.installerType,
					update: true,
				},
			};
		}

		case SET_HOME_FILTER: {
			const installerType = getInstallerType({
				home: payload,
				business: state.filter.business,
			});
			console.log({ installerType });
			return {
				...state,
				filter: {
					...state.filter,
					home: payload,
					installerType: installerType,
					update: true,
				},
			};
		}
		case SET_BUSINESS_FILTER: {
			const installerType = getInstallerType({
				business: payload,
				home: state.filter.home,
			});

			return {
				...state,
				filter: {
					...state.filter,
					business: payload,
					installerType: installerType,
					update: true,
				},
			};
		}
		case SET_COOLING_FILTER: {
			const productType = getProductType({
				cooling: payload,
				heating: state.filter.heating,
			});
			return {
				...state,
				filter: {
					...state.filter,
					cooling: payload,
					productType: productType,
					update: true,
				},
			};
		}
		case SET_HEATING_FILTER: {
			const productType = getProductType({
				heating: payload,
				cooling: state.filter.cooling,
			});
			return {
				...state,
				filter: {
					...state.filter,
					heating: payload,
					productType: productType,
					update: true,
				},
			};
		}

		case SET_PRODUCT_TYPE: {
			return {
				...state,
				filter: {
					...state.filter,
					productType: payload.productType,
					update: true,
				},
			};
		}

		case SET_ACTIVE_LOCATOR: {
			return { ...state, activeLocator: payload.locator };
		}

		case SET_BUSY: {
			return { ...state, busy: payload.busy };
		}

		case SET_EMAIL_TEMPLATES: {
			return { ...state, emailTemplates: payload.emailTemplates };
		}

		case SET_CONTACT_MODAL: {
			return {
				...state,
				contactLocator: payload.contactLocator,
			};
		}

		case SUBMIT_FORM_PENDING: {
			return { ...state, form: { busy: true, success: false } };
		}

		case SUBMIT_FORM_ERROR:
		case FORM_CLEAR_STATE: {
			return { ...state, form: { busy: false, success: false } };
		}

		case SUBMIT_FORM_SUCCESS: {
			return { ...state, form: { busy: false, success: true } };
		}

		case SET_HAS_PRIVACY_POLICY: {
			return { ...state, hasPolicy: payload };
		}

		default:
			return state;
	}
};
