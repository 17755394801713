// @ts-nocheck
import React from 'react'
import styled, { keyframes } from 'styled-components'

const Input = ({ error, name, onChange, value, placeholder, hint, dirty, type = 'text', required = false }) => {
  return (
    <InputWrapper>
      <div className={`group ${dirty ? 'dirty' : ''} ${error ? 'error' : ''}`}>
        {type === 'textarea' && <textarea name={name} required={required} rows={3} value={value} onChange={onChange} />}
        {type !== 'textarea' && <input name={name} type={type} required={required} value={value} onChange={onChange} />}
        <span className="highlight"></span>
        <span className="bar"></span>
        <label>{placeholder}</label>
      </div>
      <span className={`hint ${error ? 'error' : ''}`}>
        {error ? error : hint}
      </span>
    </InputWrapper>
  )
}

const inputHighlight = keyframes`
  from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
`

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 25px;
  text-align: left;

  .group {
    position: relative;
  }

  input, textarea {
    appearance: none;
    font-family: inherit;
    font-size:18px;
    padding: 15px 10px 5px 5px;
    display:block;
    width: 100%;
    border:none;
    border-radius: 0;
    border-bottom:1px solid #757575;

    @media only screen and (max-width: 600px) {
      font-size: 16px;
      padding-right: 0;
    }
  }

  input:focus, textarea:focus {
    outline:none;
  }

  label 				 {
    color: #8F8F8F;
    font-size:18px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:5px;
    top:10px;
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;
  }

  .hint {
    margin-top: 5px;
    display: block;
    font-size: 12px;
    color: #8F8F8F;
    padding-left: 5px;

    &.error {
      color: #ff2424;
    }
  }

  input:focus ~ label, textarea:focus ~ label, .group.dirty input ~ label, .group.dirty textarea ~ label {
    top:-10px;
    font-size:14px;
    color: #8F8F8F;
  }

  .group.error {
    input, textarea { border-color: #ff2424; }

    label {
      color: #ff2424;
    }
  }

  .highlight {
    position:absolute;
    height:60%;
    top:25%;
    left:0;
    pointer-events:none;
    opacity:0.5;
  }

  input:focus ~ .highlight, textarea:focus ~ .highlight {
    animation: ${inputHighlight} 0.3s ease;
  }

  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active
  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

`
export default Input
