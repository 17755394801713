import { DispatchAction, State, rootReducer } from "../reducers";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { useSelector, useDispatch } from "react-redux";

const composeEnhancers =
	(window as any)["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;

export const store = createStore<State, DispatchAction, null, null>(
	rootReducer,
	composeEnhancers(applyMiddleware(thunk))
);

export const useStore = () => useSelector((state: State) => state);
export const useTranslations = () =>
	useSelector((state: State) => state && state.translations);
export { useDispatch };
