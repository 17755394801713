import React, { FC } from "react";
import { Transition } from "react-transition-group";
import { useRef } from "react";

const duration = 300;

const defaultStyle = {
	transition: `opacity ${duration}ms ease-in-out`,
	opacity: 0,
};

const transitionStyles: any = {
	entering: { opacity: 1 },
	entered: { opacity: 1 },
	exiting: { opacity: 0, transform: "translateY(-5px)" },
	exited: { opacity: 0, transform: "translateY(-5px)" },
};

type T = {
	in: any;
	children: any;
};

const Fade: FC<T> = ({ in: inProp, children }) => {
	const nodeRef = useRef(null);
	return (
		<Transition nodeRef={nodeRef} in={inProp} timeout={duration}>
			{(state) => (
				<div
					ref={nodeRef}
					style={{
						...defaultStyle,
						...transitionStyles[state],
					}}
				>
					{children}
				</div>
			)}
		</Transition>
	);
};

export default Fade;
