// @ts-nocheck
import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Formik, Field } from "formik";
import get from "lodash/get";

import Input from "../input";
import Close from "../../media/close.svg";
import Loader from "../../media/loader.gif";

import { submitForm } from "../../actions/contact";
import { FORM_CLEAR_STATE } from "../../actions/types";

export const FIELDS = {
	firstName: "",
	lastName: "",
	phone: "",
	email: "",
	company: "",
	message: "",
	consent: false,
};
const REQUIRED_FIELDS = ["firstName", "lastName", "phone", "email"];

const Form = ({ locator }) => {
	const dispatch = useDispatch();
	const { form: translationForm } = useSelector(
		(state: State) => state && state.translations
	);
	const form = useSelector((state: State) => state.form);
	const locale = useSelector((state: State) => state.activeLocale);

	const parsePrivacy = (text) =>
		text.replace("<a>", `<a href="${locale.privacyPolicy}" target="_blank">`);

	return (
		<Formik
			validateOnChange={false}
			initialValues={FIELDS}
			onSubmit={(values, { setSubmitting }) => {
				dispatch(submitForm(locator, values));
			}}
			validate={(values) => {
				const errors = {};

				if (!values.consent) {
					errors.consent = get(translationForm, "errors.consent");
					return errors;
				}

				REQUIRED_FIELDS.forEach((field) => {
					if (!values[field]) {
						errors[field] = get(translationForm, "errors.required");
					}

					if (
						field === "email" &&
						!errors[field] &&
						!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
					) {
						errors.email = get(translationForm, "errors.email");
					}
				});

				return errors;
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				/* and other goodies */
			}) => {
				return (
					<form
						onSubmit={handleSubmit}
						style={{ opacity: form.busy ? 0.6 : 1 }}
					>
						<div className="input-column">
							<Input
								type="text"
								name="firstName"
								onChange={handleChange}
								value={values.firstName}
								dirty={values.firstName}
								error={errors.firstName}
								placeholder={`${get(
									translationForm,
									"inputFields.firstName.placeholder"
								)} *`}
								hint={get(translationForm, "inputFields.firstName.hint")}
							/>
							<Input
								type="text"
								name="lastName"
								onChange={handleChange}
								value={values.lastName}
								dirty={values.lastName}
								error={errors.lastName}
								placeholder={`${get(
									translationForm,
									"inputFields.lastName.placeholder"
								)} *`}
								hint={get(translationForm, "inputFields.lastName.hint")}
							/>
						</div>
						<div className="input-column">
							<Input
								type="text"
								name="phone"
								onChange={handleChange}
								value={values.phone}
								dirty={values.phone}
								error={errors.phone}
								placeholder={`${get(
									translationForm,
									"inputFields.phone.placeholder"
								)} *`}
								hint={get(translationForm, "inputFields.phone.hint")}
							/>
							<Input
								type="email"
								name="email"
								onChange={handleChange}
								value={values.email}
								dirty={values.email}
								error={errors.email}
								placeholder={`${get(
									translationForm,
									"inputFields.email.placeholder"
								)} *`}
								hint={get(translationForm, "inputFields.email.hint")}
							/>
						</div>
						<div className="input-column">
							<Input
								type="text"
								name="company"
								onChange={handleChange}
								value={values.company}
								dirty={values.company}
								error={errors.company}
								placeholder={`${get(
									translationForm,
									"inputFields.company.placeholder"
								)}`}
								hint={get(translationForm, "inputFields.company.hint")}
							/>
						</div>
						<div className="input-column">
							<Input
								type="textarea"
								name="message"
								onChange={handleChange}
								value={values.message}
								dirty={values.message}
								error={errors.message}
								placeholder={`${get(
									translationForm,
									"inputFields.message.placeholder"
								)}`}
								hint={get(translationForm, "inputFields.message.hint")}
							/>
						</div>
						<label className="consent">
							<Field type="checkbox" name="consent" />
							<div>
								<span
									dangerouslySetInnerHTML={{
										__html: parsePrivacy(get(translationForm, "consent")),
									}}
								/>
								{errors.consent && (
									<p style={{ textAlign: "left" }} class="error">
										{errors.consent}
									</p>
								)}
							</div>
						</label>
						<div className="loader-wrapper">
							<button
								className={`btn-ss ${form.busy ? "disabled" : ""}`}
								disabled={form.busy}
								type="submit"
							>
								{get(translationForm, "button")}
							</button>
							{form.busy && <img className="loader" src={Loader} />}
						</div>
					</form>
				);
			}}
		</Formik>
	);
};

const ContactModal = ({ locator, setLocator }) => {
	const { form: translationForm } = useSelector(
		(state: State) => state && state.translations
	);
	const form = useSelector((state: State) => state.form);

	const ref = useRef();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: FORM_CLEAR_STATE });
	}, [locator]);

	return (
		<Modal className={`${locator ? "open" : ""}`}>
			{locator && (
				<ModalContent ref={ref}>
					<img src={Close} className="close" onClick={() => setLocator(null)} />
					{!form.success && (
						<div>
							<h1 className="title">
								{get(translationForm, "title", "").replace(
									"{locator}",
									locator.title
								)}
							</h1>
							<p>{get(translationForm, "subtitle")}</p>
							<Form locator={locator} />
						</div>
					)}
					{form.success && (
						<div>
							<h1 className="title">
								{get(translationForm, "thankYou.title")}
							</h1>
							<p>{get(translationForm, "thankYou.subtitle")}</p>
							<button
								className={`btn-ss`}
								onClick={() => setLocator(null)}
								style={{ marginTop: "10px" }}
							>
								{get(translationForm, "thankYou.buttonText")}
							</button>
						</div>
					)}
				</ModalContent>
			)}
		</Modal>
	);
};

const Modal = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: 100%;
	width: 100%;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.85);
	opacity: 0;
	pointer-events: none;
	transition: opacity 1s ease;

	&.open {
		opacity: 1;
		pointer-events: initial;
	}

	@media only screen and (max-width: 600px) {
		text-align: center;
	}
`;

const ModalContent = styled.div`
	background: #fff;
	padding: 0 4rem;
	border-radius: 8px;
	width: 100%;
	max-width: 800px;
	max-height: 100%;
	overflow-y: auto;
	position: relative;

	@media only screen and (max-width: 600px) {
		padding: 0 1rem;
		max-height: 95%;
	}

	& > div {
		margin: 4rem;

		@media only screen and (max-width: 600px) {
			margin: 4rem 0;
		}
	}

	.title {
		margin-top: 0;

		@media only screen and (max-width: 600px) {
			font-size: 24px;
		}
	}

	.error {
		color: #ff2424;
	}

	.close {
		position: absolute;
		right: 15px;
		top: 15px;
		width: 20px;
		height: auto;
		cursor: pointer;
	}

	.loader-wrapper {
		display: flex;

		.loader {
			width: 40px;
			display: inline-block;
			margin-left: 10px;
		}
	}

	form {
		margin-top: 50px;
		position: relative;

		.input-column {
			display: flex;
			margin: 0 -15px;

			& > div {
				flex-grow: 1;
				margin-left: 15px;
				margin-right: 15px;

				@media only screen and (max-width: 600px) {
					margin-left: 0;
					margin-right: 0;
				}
			}

			@media only screen and (max-width: 600px) {
				margin: 0;
				flex-wrap: wrap;
			}
		}

		.consent {
			display: flex;
			text-align: left;
			margin-bottom: 24px;

			div {
				padding-left: 10px;
			}

			input {
				margin-right: 0.4rem;
			}
		}
	}

	.btn-ss {
		display: inline-flex;
		position: relative;
		align-items: center;
		justify-content: center;
		height: 2.75rem;
		padding: 0 2rem;
		border: 1px solid #000;
		border-radius: 45px;
		color: #000;
		font-size: 0.8125rem;
		font-weight: bold;
		letter-spacing: 0.08em;
		line-height: 0;
		text-decoration: none !important;
		text-transform: uppercase;
		cursor: pointer;
		background: transparent;
		outline: 0;

		@media only screen and (max-width: 600px) {
			font-size: 14px;
		}

		&.black-full {
			border: 1px solid #000;
			background: #000;
			color: #fff;

			&:hover {
				border: 1px solid #fff;
				background: #fff;
				color: #000;
			}
		}

		&.white-full {
			background: #fff;
			color: #000;
			border: 1px solid #fff;

			&:hover {
				border: 1px solid #000;
				background: #000;
			}
		}

		&:hover {
			background: #000;
			color: #fff;
		}

		&.disabled {
			cursor: not-allowed;
			pointer-events: none;
			background: rgba(255, 255, 255, 0.25);
		}
	}

	.alert {
		padding: 0.8rem 0.5rem;
		border-radius: 4px;
		margin-top: 1rem;

		span {
			font-size: 16px;
		}

		&--success {
			border: 1px solid #237123;
			background: rgb(227 253 227 / 85%);
		}

		&--error {
			border: 1px solid #e06060;
			background: rgb(247 204 204 / 85%);
		}
	}
`;

export default ContactModal;
