import React, { useState, FC, useEffect } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import Markers from "./Markers";
import { IUseCenter } from "../../interfaces";
import { useSelector } from "react-redux";
import pick from "lodash/pick";
import { State } from "../../reducers";

const containerStyle = {
	width: "100%",
	height: "100vh",
};

type T = {
	useCenter: IUseCenter;
	center: any;
	setCenter: any;
	setMap: any;
	map: any;
	setContactLocator: any;
};

const libraries = ["geometry", "drawing", "places"] as any;
const Map: FC<T> = (props) => {
	const ZOOM_LEVEL = 6;
	const CENTER = { lat: 53.3942073, lng: -2.153067999999999 };

	const [center, setCenter] = props.useCenter;
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: "",
		libraries,
	});
	const { activeLocale } = useSelector((state: State) => state);
	// const [map, setMap] = React.useState(null);
	// const [, setContactLocator] = useState(null);
	// const setCenter = React.useCallback((map: any) => map.panTo(50, 50), []);

	useEffect(() => {
		if (!setCenter) return;
		if (activeLocale) {
			// props.history?.push(`/${activeLocale.slug.current}`);

			// dispatch(filterLocators());
			setCenter(
				activeLocale.coordinates
					? pick(activeLocale.coordinates, "lat", "lng")
					: CENTER
			);
		}
	}, [activeLocale, setCenter]);

	const onLoad = React.useCallback(function callback(map: any) {
		// This is just an example of getting and using the map instance!!! don't just blindly copy!
		// const bounds = new window.google.maps.LatLngBounds(center);
		// map.fitBounds(bounds);
		map.setZoom(ZOOM_LEVEL);
		// map.panTo({ lat: -34, lng: -151 });
		// map.options.disableDefaultUI = true;

		props.setMap(map);
	}, []);

	const onUnmount = React.useCallback(function callback() {
		props.setMap(null);
	}, []);

	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={center}
			zoom={ZOOM_LEVEL}
			onLoad={onLoad}
			onUnmount={onUnmount}
			options={{ disableDefaultUI: true }}
		>
			{/* Child components, such as markers, info windows, etc. */}

			<Markers map={props.map} setContactLocator={props.setContactLocator} />
			<></>
		</GoogleMap>
	) : (
		<></>
	);
};

export default React.memo(Map);
