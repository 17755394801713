import get from "lodash/get";
import orderBy from "lodash/orderBy";

import apiClient from "./api";
import { SET_DATA, SET_ACTIVE_LOCALE } from "./types";
import {
	Metric,
	ILocale,
	ILocator,
	InstallerType,
	ProductType,
	ICoordinates,
} from "../interfaces";
import {
	SET_EMAIL_TEMPLATES,
	SET_METRIC,
	SET_BUSY,
	SET_TRANSLATION_AND_COPY,
	SET_READY,
	SET_FILTERED_LOCATORS,
	SET_HAS_PRIVACY_POLICY,
} from "./types";

const distanceToTarget = (locator: ILocator, target: ICoordinates) => {
	const lat1 = get(locator, "location.coordinates.lat");
	const lat2 = target.lat;
	const lon1 = get(locator, "location.coordinates.lng");
	const lon2 = target.lng;

	const p = 0.017453292519943295; // Math.PI / 180
	const c = Math.cos;
	const a =
		0.5 -
		c((lat2 - lat1) * p) / 2 +
		(c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

	return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
};

function fetchContent() {
	return async function action(dispatch: any, getState: any) {
		const locatorQuery =
			'*[_type == "location"] {_id, title, customOrder, location, contact, installerType, productType, extra}';
		const localeQuery =
			'*[_type == "country"] {title, slug, coordinates, translations, translationsForm, copy, installerTemplate, userTemplate, "privacyPolicy": privacyPolicy.asset->url}';

		const [locators, locales] = await Promise.all([
			apiClient.fetch(locatorQuery),
			apiClient.fetch(localeQuery),
		]);

		// When a country has no privacy policy set, use UK's
		const defaultLocale = locales.find(
			(locale: any) => locale.slug.current === "uk"
		);
		if (defaultLocale) {
			locales.forEach((locale: any) => {
				locale.hasOwnPrivacyPolicy = !!locale.privacyPolicy;
				if (!locale.privacyPolicy)
					locale.privacyPolicy = defaultLocale.privacyPolicy;

				locale.translations = {
					...defaultLocale.translations,
					...locale.translations,
				};
				locale.translationsForm = {
					...defaultLocale.translationsForm,
					...locale.translationsForm,
				};
				locale.userTemplate = {
					...defaultLocale.userTemplate,
					...locale.userTemplate,
				};
				locale.installerTemplate = {
					...defaultLocale.installerTemplate,
					...locale.installerTemplate,
				};
				locale.copy = { ...defaultLocale.copy, ...locale.copy };
			});
		}

		return dispatch({ type: SET_DATA, payload: { locators, locales } });
	};
}

function setTranslations(locale: ILocale) {
	return async function action(dispatch: any, getState: any) {
		const translations = {
			general: locale.translations,
			form: locale.translationsForm,
		};
		const { copy } = locale;

		return dispatch({
			type: SET_TRANSLATION_AND_COPY,
			payload: { translations, copy },
		});
	};
}

function setEmailTemplates(locale: ILocale) {
	return async function action(dispatch: any, getState: any) {
		const emailTemplates = {
			user: locale.userTemplate,
			installer: locale.installerTemplate,
		};

		return dispatch({ type: SET_EMAIL_TEMPLATES, payload: { emailTemplates } });
	};
}

export function setActiveLocale(location: any) {
	return async function action(dispatch: any, getState: any) {
		const slug =
			location.pathname &&
			location.pathname.replace("/", "").trim().toLowerCase();
		const locales = getState().locales;

		let activeLocale = locales.find(
			(locale: ILocale) => locale.slug.current === slug
		);
		if (!activeLocale) {
			// use default locale, UK
			activeLocale = locales.find(
				(locale: ILocale) => locale.slug.current === "uk"
			);
		}

		console.log({ activeLocale });

		if (activeLocale) {
			if (activeLocale.slug.current !== "uk") {
				// force km as metric when not in uk
				dispatch({
					type: SET_METRIC,
					payload: { metric: Metric.KM, update: false },
				});
			}

			await dispatch(setTranslations(activeLocale));
			await dispatch(setEmailTemplates(activeLocale));
			dispatch({
				type: SET_HAS_PRIVACY_POLICY,
				payload: activeLocale.hasOwnPrivacyPolicy,
			});

			return dispatch({ type: SET_ACTIVE_LOCALE, payload: { activeLocale } });
		}
	};
}

export function setActiveLocaleFromLocale(locale: ILocale) {
	return async function action(dispatch: any, getState: any) {
		if (locale && locale.slug.current !== "uk") {
			// force km as metric when not in uk
			dispatch({
				type: SET_METRIC,
				payload: { metric: Metric.KM, update: false },
			});
		}

		await dispatch(setTranslations(locale));
		return dispatch({
			type: SET_ACTIVE_LOCALE,
			payload: { activeLocale: locale },
		});
	};
}

export function filterLocators() {
	return async function action(dispatch: any, getState: any) {
		dispatch({ type: SET_BUSY, payload: { busy: true } });

		const state = getState();
		let filtered = state.locators;

		// filter on locale
		if (state.activeLocale) {
			filtered = filtered.filter(
				(locator: ILocator) =>
					get(locator, "location.country._ref") ===
					state.activeLocale.slug.current
			);
		}

		// filter on installer type and product type
		const { filter } = state;

		if (filter.installerType) {
			filtered = filtered.filter((locator: ILocator) => {
				if (locator.installerType === InstallerType.HOME_AND_BUSINESS)
					return true;
				if (filter.installerType === InstallerType.HOME)
					return locator.installerType === InstallerType.HOME;
				if (filter.installerType === InstallerType.BUSINESS)
					return locator.installerType === InstallerType.BUSINESS;
				return true;
			});
		}

		// if (filter.installerType !== InstallerType.HOME_AND_BUSINESS) {
		// 	filtered = filtered.filter(
		// 		(locator: ILocator) =>
		// 			locator.installerType === filter.installerType ||
		// 			locator.installerType === InstallerType.HOME_AND_BUSINESS
		// 	);
		// }

		if (filter.productType) {
			filtered = filtered.filter((locator: ILocator) => {
				if (locator.productType === ProductType.COOLING_AND_HEATING)
					return true;
				if (filter.productType === ProductType.COOLING)
					return locator.productType === ProductType.COOLING;
				if (filter.productType === ProductType.HEATING)
					return locator.productType === ProductType.HEATING;
				return true;
			});
		}

		// Backup
		// if (filter.productType !== ProductType.COOLING_AND_HEATING) {
		// 	console.log({ filter });
		// 	const getProductType = () => {
		// 		if (!filter.cooling && filter.heating) return ProductType.HEATING;
		// 		if (filter.cooling && !filter.heating) return ProductType.COOLING;
		// 		if (!filter.cooling && !filter.heating) return null;
		// 	};

		// 	const productType = getProductType();
		// 	if (productType) {
		// 		filtered = filtered.filter(
		// 			(locator: ILocator) => locator.productType === productType
		// 		);
		// 	}
		// 	// Old
		// 	// filtered = filtered.filter(
		// 	// 	(locator: ILocator) =>
		// 	// 		locator.productType === filter.productType ||
		// 	// 		locator.productType === ProductType.COOLING_AND_HEATING
		// 	// );
		// }
		// End backup

		// filter on target
		if (filter.target) {
			if (state.targetNeedCalculation) {
				filtered.forEach((locator: ILocator) => {
					locator.distanceToTarget = distanceToTarget(locator, filter.target);
				});
			}

			const maxDistance =
				filter.metric === Metric.MILES
					? filter.distance * 1.609344
					: filter.distance;
			filtered = filtered.filter(
				(locator: ILocator) => locator.distanceToTarget < maxDistance
			);
			filtered = orderBy(filtered, ["distanceToTarget", "title"]);
		} else {
			filtered = [
				...orderBy(
					filtered.filter((el: any) => !!+el.customOrder),
					["customOrder", "title"],
					["desc", "asc"]
				),
				...orderBy(
					filtered.filter((el: any) => !+el.customOrder),
					["title"]
				),
			];
		}

		console.log({ filter, filtered });

		return dispatch({
			type: SET_FILTERED_LOCATORS,
			payload: { filteredLocators: filtered },
		});
	};
}

export function initializeStore(location: any) {
	return async function action(dispatch: any, getState: any) {
		// Locations & Locales from Sanity
		await dispatch(fetchContent());

		// Set active locale according to location
		await dispatch(setActiveLocale(location));

		dispatch({ type: SET_READY, payload: { ready: true } });
	};
}
