/// @ts-nocheck
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// import 'isomorphic-fetch';
import React from "react";
import ReactDOM from "react-dom";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

import { Home } from "./containers";
import Home2 from "./containers/Home2";
import Map from "./components/map/Map";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";

import "./index.css";

require("es6-promise").polyfill();

if (/*@cc_on!@*/ false || !!document.documentMode) {
	alert(
		"This site does not support Internet Explorer 11 (IE11) or lower. It’s recommended to use Google Chrome, Microsoft Edge or Firefox browsers to continue."
	);
}
const container = document.getElementById("root");

const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
	<Provider store={store}>
		<React.StrictMode>
			<BrowserRouter>
				<Routes>
					<Route path={"/:country"} element={<Home2 />} />
					<Route path={"/"} element={<Home2 />} />
				</Routes>
				{/* <Route path={"/"} component={Home} /> */}
			</BrowserRouter>
		</React.StrictMode>
	</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
