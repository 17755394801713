import React, { FC } from "react";

import { ReactComponent as Coolingheatinghomebusiness } from "./coolingheatinghomebusiness.svg";
import { ReactComponent as Coolingbusiness } from "./coolingbusiness.svg";
import { ReactComponent as Heatingbusiness } from "./heatingbusiness.svg";
import { ReactComponent as Coolingheatingbusiness } from "./coolingheatingbusiness.svg";
import { ReactComponent as Coolinghomebusiness } from "./coolinghomebusiness.svg";
import { ReactComponent as Heatinghomebusiness } from "./heatinghomebusiness.svg";

import { getMarkerName } from "../../utils";
type T = {
	productType: string;
	installerType: string;
};

const icons: { [key: string]: any } = {
	coolingheatinghomebusiness: <Coolingheatinghomebusiness />,
	coolingbusiness: <Coolingbusiness />,
	heatingbusiness: <Heatingbusiness />,
	coolingheatingbusiness: <Coolingheatingbusiness />,
	coolinghomebusiness: <Coolinghomebusiness />,
	heatinghomebusiness: <Heatinghomebusiness />,
	cooling: <Coolinghomebusiness />,
};
const Icon: FC<T> = ({ productType, installerType }) => {
	const name = getMarkerName(productType, installerType);

	const TheIcon = icons[name] || <Coolingheatinghomebusiness />;

	return <div style={{ maxWidth: "2em" }}>{TheIcon}</div>;
};

export default Icon;
