import React, { useState } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";

import { State } from "../../reducers";
import { ReactComponent as MarkerHeating } from "../../media/empty_marker.svg";
import { ReactComponent as MarkerCooling } from "../../media/empty_marker_cool.svg";
import { ReactComponent as Home } from "../../media/home.svg";
import { ReactComponent as Business } from "../../media/business.svg";
import styled from "styled-components";

interface MapLegendProps {}

export const Legend: React.FC<MapLegendProps> = () => {
	const [isOpen, setIsOpen] = useState(false);
	const { general: translationGeneral } = useSelector(
		(state: State) => state && state.translations
	);

	React.useEffect(() => {
		console.log({ isOpen });
	}, [isOpen]);

	return (
		<Container onClick={() => setIsOpen(!isOpen)}>
			{isOpen ? (
				<div className="items">
					<Item>
						<MarkerHeating />
						<span>{get(translationGeneral, "heating")}</span>
					</Item>
					<Item>
						<MarkerCooling />
						<span>{get(translationGeneral, "cooling")}</span>
					</Item>
					<Item>
						<Home />
						<span>{get(translationGeneral, "forHome")}</span>
					</Item>
					<Item>
						<Business />
						<span>{get(translationGeneral, "forBusiness")}</span>
					</Item>
				</div>
			) : (
				<span style={{ fontWeight: 700, textTransform: "uppercase" }}>
					legenda
				</span>
			)}
		</Container>
	);
};

const Container = styled.div`
	cursor: pointer;
	position: absolute;
	bottom: 20px;
	right: 8px;
	border-radius: 10px;
	padding: 1em;
	font-size: 14px;
	min-width: 150px;
	text-align: center;
	background-color: #fff;
	box-shadow: 0 0 30px -10px rgb(0 0 0 / 71%);

	.items {
		display: flex;
		align-items: end;
		justify-content: space-around;
		gap: 1em;
	}
`;
const Item = styled.div`
	display: flex;
	flex-flow: column;
	gap: 0.75em;
	align-items: center;
	text-transform: uppercase;
	justify-content: center;
	font-size: 12px;
`;
