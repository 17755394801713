/// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Map2 from "../../components/map/Map";
import FilterSearch from "../../components/filter/FilterSearch";
import { useStore } from "../../store/";
import FilterBadges from "../../components/filter/FilterBadges";
import { useWindowSize } from "../../utils";

import get from "lodash/get";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import Installers from "../../components/installers/Installers";
import Filter2 from "../../components/filter/Filter2";
import { Legend } from "../../components/legend";
import ContactForm from "../../components/contact-form";

import { State } from "../../reducers";
import { initializeStore, filterLocators } from "../../actions";
import { IUseCenter } from "../../interfaces";
// import { InfoOutlined } from "@mui/icons-material";
import Tooltip from "../../components/Tooltip";
import "./styles.css";
import { Skeleton } from "@mui/material";
import Input from "../../components/input";
// import { Replay } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"; // const ZOOM_LEVEL = 6;
const CENTER = { lat: 53.3942073, lng: -2.153067999999999 };

const Home: FC<T> = (props) => {
	const [contactLocator, setContactLocator] = useState(null);

	return (
		<>
			<ContactForm locator={contactLocator} setLocator={setContactLocator} />
			<Maps props={props} setContactLocator={setContactLocator} />
		</>
	);
};

const Maps = ({ setContactLocator, props }) => {
	const state = useStore();
	const dispatch = useDispatch();
	const [filtersOpen, setFiltersOpen] = useState(false);
	const location = useLocation();
	// const ref = useRef();
	const {
		filter,

		ready,
		activeLocale,
	} = useSelector((state: State) => state);

	const useCenter: IUseCenter = useState(CENTER);
	const listContainerRef = useRef<HTMLDivElement>(null);
	const [map, setMap] = useState(null);
	const { setCenter, center } = useCenter;
	useEffect(() => {
		if (!ready) {
			dispatch(initializeStore(location));
		}
	}, [ready, dispatch, location]);

	const size = useWindowSize();

	useEffect(() => {
		if (activeLocale) {
			props.history?.push(`/${activeLocale.slug.current}`);
			dispatch(filterLocators());
		}
	}, [activeLocale, setCenter, props.history, dispatch]);

	useEffect(() => {
		if (filter.update) {
			dispatch(filterLocators());
		}
	}, [filter, dispatch, ready]);

	return (
		<Wrapper>
			{/* Map container */}

			<div className={"container"} ref={listContainerRef}>
				<div className={"mainContainer"}>
					<div className="topContainer">
						<div
							style={{
								display: "flex",
								paddingTop: "1em",
								paddingBottom: "1em",
								gap: "1em",
								alignItems: "center",
							}}
							className={"sidePadding"}
						>
							<h1 style={{ marginBottom: 0 }}>{get(state, "copy.title")}</h1>
							<Tooltip />
						</div>
						<FilterSearch
							useCenter={useCenter}
							filtersOpen={filtersOpen}
							toggleFiltersOpen={() => setFiltersOpen(!filtersOpen)}
						/>
						<div className="sidePadding">
							<FilterBadges />
						</div>
						<div
							// className="sidePadding"
							style={{
								transition: "all 0.3s ease-in-out",
								maxHeight: filtersOpen ? "120vh" : "0px",
								opacity: filtersOpen ? "1" : "0",
								overflow: "hidden",
								borderBottom: "2px solid #eee",
							}}
						>
							<div className="sidePadding">
								<Filter2
									useCenter={useCenter}
									toggleFiltersOpen={() => setFiltersOpen(!filtersOpen)}
								/>
							</div>
						</div>
					</div>
					{size.width < BREAKPOINT && (
						<div className="mapContainer desktop">
							<Map2
								setMap={setMap}
								useCenter={useCenter}
								center={center}
								setCenter={setCenter}
								setContactLocator={setContactLocator}
							/>
						</div>
					)}

					<div className="listContainer">
						<div className="total sidePadding">
							{ready ? (
								<>
									{" "}
									Total{" "}
									<span style={{ fontWeight: "bold" }}>
										{state.filteredLocators?.length}
									</span>{" "}
									locations
								</>
							) : (
								<Skeleton
									sx={{ textAlign: "inherit", display: "inline-block" }}
									variant="text"
									width={100}
								/>
							)}
						</div>
						<Installers
							listContainerRef={listContainerRef}
							setContactLocator={setContactLocator}
							map={map}
						/>
					</div>
				</div>
				{size.width > BREAKPOINT && (
					<div className="mapContainer desktop">
						<Map2
							setMap={setMap}
							useCenter={useCenter}
							center={center}
							setCenter={setCenter}
							map={map}
							setContactLocator={setContactLocator}
						/>
					</div>
				)}
			</div>

			{/* <Filter
				googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC9b1AB2O42mz5tRhb3grIzdRM5XCVnezU&v=3.exp&libraries=geometry,drawing,places"
				loadingElement={<div style={{ height: `100%` }} />}
				mapRef={ref}
				setCenter={setCenter}
				setContactLocator={setContactLocator}
			/> */}
			<div className="legendContainer">
				<Legend isOpened={true} />
			</div>
			<ToastContainer
				position="bottom-right"
				toastClassName="bg-red-600 relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
			/>
			{activeLocale?.privacyPolicy && (
				<PrivacyContainer>
					<a href={activeLocale.privacyPolicy} target="_blank" rel="noreferrer">
						Privacy policy
					</a>
				</PrivacyContainer>
			)}
		</Wrapper>
	);
};

export default Home;

const BREAKPOINT = 640;

const PrivacyContainer = styled.div`
	position: fixed;
	bottom: 25px;
	left: 50%;
	transform: translateX(-50%);

	@media only screen and (max-width: 600px) {
		position: fixed;
		left: 0;
		bottom: auto;
		transform: rotateZ(90deg) translate(50%, 175%);
		top: 100px;
		font-size: 14px;
	}
`;

const Wrapper = styled.div`
	.container {
		display: flex;
		min-height: 100svh;
		max-height: 100svh;
		overflow: hidden;
		@media only screen and (max-width: ${BREAKPOINT}px) {
			position: relative;
			z-ndex: 0;
			max-height: 100svh;
			overflow: scroll;
			/* background: white; */
		}
	}

	.mainContainer {
		flex: 1;
		@media only screen and (min-width: ${BREAKPOINT}px) {
			box-shadow: 0 0 30px -10px rgb(0 0 0 / 71%);
			max-width: 400px;
			z-index: 1;
		}
	}
	.mapContainer {
		/* display: none; */
		@media only screen and (max-width: ${BREAKPOINT}px) {
			position: fixed;
			inset: 0;
			/* z-index: -1; */
			display: block;
		}

		&.desktop {
			@media only screen and (min-width: 721px) {
				display: block;
				flex: 1;
			}
		}
	}
	.topContainer {
		background: white;
		z-index: 1;
		@media only screen and (max-width: ${BREAKPOINT}px) {
			/* height: 100vh; */
			position: fixed;
			top: 0;
			left: 0;
			box-shadow: 0 0 30px -10px rgb(0 0 0 / 71%);
			right: 0;
		}
	}
	.listContainer {
		position: relative;

		background-color: white;
		@media only screen and (max-width: ${BREAKPOINT}px) {
			position: absolute;
			top: 100svh;
			width: 100%;
			transform: translateY(-4em);
			border-top-left-radius: 1.5em;
			border-top-right-radius: 1.5em;
			/* text-align: center; */
			box-shadow: 0 0 30px -10px rgb(0 0 0 / 71%);
			padding: 0.5em;
			&::before {
				content: "";
				display: block;
				width: 4em;
				height: 3px;
				background-color: #d4d4d4;
				margin: auto;
			}
		}

		.total {
			@media only screen and (max-width: ${BREAKPOINT}px) {
				text-align: center;
				border-top: none;
			}
			border-bottom: 2px solid #eee;
			/* border-top: 2px solid #eee; */
			padding-top: 1em;
			padding-bottom: 1em;
		}
	}

	.legendContainer {
		@media only screen and (max-width: ${BREAKPOINT}px) {
			display: none;
		}
	}

	.btn-ss {
		display: inline-flex;
		position: relative;
		align-items: center;
		justify-content: center;
		height: 2.75rem;
		padding: 0 2rem;
		border: 1px solid #000;
		border-radius: 45px;
		color: #000;
		font-size: 10px;
		font-weight: bold;
		letter-spacing: 0.08em;
		line-height: 0;
		text-decoration: none !important;
		text-transform: uppercase;
		cursor: pointer;
		background: transparent;
		outline: 0;

		& .icon {
			font-size: 0.7em;
			padding-right: 0.5em;
		}

		&.black-full {
			border: 1px solid #000;
			background: #000;
			color: #fff;

			&:hover {
				border: 1px solid #000;
				background: #fff;
				color: #000;
			}
		}

		&.white-full {
			background: #fff;
			color: #000;
			border: 1px solid #fff;

			&:hover {
				border: 1px solid #000;
				background: #000;
			}
		}

		&:hover {
			background: #000;
			color: #fff;
		}
	}

	.sidePadding {
		padding-left: 1em;
		padding-right: 1em;
	}
	.btn-text {
		background: none;
		border: none;
		display: flex;
		align-items: center;
		text-decoration: underline;
		cursor: pointer;
	}
`;
