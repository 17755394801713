import axios from "axios";
import get from "lodash/get";
import { toast } from "react-toastify";

import {
	SUBMIT_FORM_ERROR,
	SUBMIT_FORM_SUCCESS,
	SUBMIT_FORM_PENDING,
} from "./types";

const formFields = [
	"firstName",
	"lastName",
	"phone",
	"email",
	"company",
	"message",
];

const HANDLE_KEY_MAP: any = {
	firstName: "firstName",
	lastName: "lastName",
	phone: "phone",
	email: "email",
	installer: "installer.title",
	message: "message",
};

const formData = (data: any) => {
	let dataToString = "";
	formFields.forEach((field) => {
		if (data[field]) {
			dataToString += `${data[field]}\n`;
		}
	});

	return dataToString;
};

const parseEmailTemplate = (template: any, data: any) => {
	// helper function to replace handles with form input
	const replaceValues = (field: string) => {
		if (field) {
			field = field.replace(
				"{{firstName}}",
				get(data, HANDLE_KEY_MAP.firstName)
			);
			field = field.replace("{{lastName}}", get(data, HANDLE_KEY_MAP.lastName));
			field = field.replace("{{phone}}", get(data, HANDLE_KEY_MAP.phone));
			field = field.replace("{{email}}", get(data, HANDLE_KEY_MAP.email));
			field = field.replace(
				"{{installer}}",
				get(data, HANDLE_KEY_MAP.installer)
			);
			field = field.replace("{{message}}", get(data, HANDLE_KEY_MAP.message));
			field = field.replace(
				"{{installerUrl}}",
				`https://www.installer-locator-seace.com/${get(
					data,
					"installer.country"
				)}`
			);
			field = field.replace("{{form}}", formData(data));
			field = field.replace(/\n/g, "<br />");
		}

		return field;
	};

	// for each email field, replace all handles with submission's value
	const templateParsed: any = {};
	templateParsed.subject = replaceValues(template.emailSubject);
	templateParsed.title = replaceValues(template.emailBody.header);
	templateParsed.msg = replaceValues(template.emailBody.message);
	templateParsed.footer = replaceValues(template.emailBody.footer);
	templateParsed.legal = replaceValues(template.emailBody.legal);

	return templateParsed;
};

export function submitForm(installer: any, data: any) {
	return async function action(dispatch: any, getState: any) {
		dispatch({ type: SUBMIT_FORM_PENDING });

		try {
			// Payload
			const payload = {
				...data,
				installer: {
					id: installer._id,
					title: installer.title,
					email: get(installer, "contact.email"),
					country: get(installer, "location.country._ref"),
				},
			};

			// Manage template
			const { user: userTemplate, installer: installerTemplate } =
				getState().emailTemplates;
			const emailTemplates = {
				user: parseEmailTemplate(userTemplate, payload),
				installer: parseEmailTemplate(installerTemplate, payload),
			};
			payload.dynamicTemplateData = emailTemplates;
			payload.installer.country = undefined;

			// // Send request
			const mode = window.location.href.includes(
				"https://installer-locator-seace.com"
			)
				? "prod"
				: "dev";
			const url =
				mode === "prod"
					? "https://8vter950s7.execute-api.eu-central-1.amazonaws.com/prod/contact"
					: "https://myjf2bqll5.execute-api.eu-central-1.amazonaws.com/dev/contact";
			await axios.post(url, payload);

			toast.success(get(getState(), "translations.form.notifications.success"));
			return dispatch({ type: SUBMIT_FORM_SUCCESS });
		} catch (err) {
			console.log(err);
			toast.error(get(getState(), "translations.form.notifications.error"));
			return dispatch({ type: SUBMIT_FORM_ERROR });
		}
	};
}
