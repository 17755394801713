import React, { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { State } from "../../reducers";
import pick from "lodash/pick";
import get from "lodash/get";
import { getMarkerName } from "../../utils";
import TypeSummary from "../installers/TypeSummary";
import { useTransition, animated } from "@react-spring/web";

import { SET_ACTIVE_LOCATOR } from "../../actions/types";

import { MarkerF, InfoWindowF } from "@react-google-maps/api";

type T = {
	setContactLocator: any;
	map: any;
};

const Markers: FC<T> = (props) => {
	const dispatch = useDispatch();

	const { filteredLocators, activeLocator, hasPolicy } = useSelector(
		(state: State) => state
	);

	const transitions = useTransition(
		filteredLocators.map((data) => data),
		{
			key: (item: any) => item._id,
			enter: { opacity: 1, transform: "translateY(20)" },
			from: { height: 0, opacity: 0, transform: "translateY(10)" },
			leave: { height: 0, opacity: 0 },
			config: { duration: 2000 },
		}
	);

	// const getTranslationFromValue = (
	// 	translationGeneral: string,
	// 	value: string
	// ) => {
	// 	if (value === InstallerType.HOME) {
	// 		return get(translationGeneral, "forHome", "For home");
	// 	} else if (value === InstallerType.BUSINESS) {
	// 		return get(translationGeneral, "forBusiness", "For business");
	// 	} else if (value === InstallerType.HOME_AND_BUSINESS) {
	// 		return get(
	// 			translationGeneral,
	// 			"forHomeAndBusiness",
	// 			"For home & business"
	// 		);
	// 		// } else if (value === ProductType.COOLING) {
	// 		//   return get(translationGeneral, 'cooling', 'Cooling')
	// 		// } else if (value === ProductType.HEATING) {
	// 		//   return get(translationGeneral, 'heating', 'Heating')
	// 		// } else if (value === ProductType.COOLING_AND_HEATING) {
	// 		//   return get(translationGeneral, 'coolingAndHeating', 'Cooling and Heating')
	// 	}
	// };
	return (
		<>
			{/* Google Maps markers */}
			{filteredLocators &&
				transitions((style, locator) => {
					// if (!locator) return;
					// const icon = (MarkerIconMapper as any)[
					// 	locator.installerType || InstallerType.HOME_AND_BUSINESS
					// ];

					const position = pick(locator.location.coordinates, "lat", "lng");
					const markerName = getMarkerName(
						locator.productType,
						locator.installerType
					);
					if (!position.lat || !position.lng) return null;

					return (
						<animated.div style={style}>
							<MarkerF
								position={position}
								icon={{
									url: require(`../Icon/${markerName}.svg`),
									scaledSize: new google.maps.Size(50, 50),
								}}
								// optimized={false}
								onClick={() => {
									if (!activeLocator || locator._id !== activeLocator._id) {
										dispatch({
											type: SET_ACTIVE_LOCATOR,
											payload: { locator },
										});
										props.map.panTo(
											pick(locator.location.coordinates, "lat", "lng")
										);
									}
								}}
								key={locator._id}
							>
								{activeLocator && activeLocator._id === locator._id && (
									<InfoWindowF>
										<div
											style={{
												fontSize: "16px",
												padding: "0.5em",
												minWidth: "320px",
											}}
										>
											<div style={{ marginBottom: "1em" }}>
												<TypeSummary locator={locator} />
											</div>
											<p style={{ marginBottom: "1em" }}>
												<b>{get(locator, "title")}</b>
											</p>

											<div style={{ marginBottom: "1em" }}>
												{get(locator, "extra.text") && (
													<p>{get(locator, "extra.text")}</p>
												)}
												<p>{get(locator, "location.street")}</p>
												<p>
													{get(locator, "location.zip")}{" "}
													{get(locator, "location.city")}
												</p>
												{/* <p>
												{getTranslationFromValue(
													translationGeneral,
													locator.installerType
												)}
											</p> */}
												{/* <p>
												{getTranslationFromValue(
													translationGeneral,
													locator.productType
												)}
											</p> */}
											</div>

											{get(locator, "contact.email") && (
												<p>
													<a href={`mailto:${get(locator, "contact.email")}`}>
														{get(locator, "contact.email")}
													</a>
													<br></br>
												</p>
											)}
											{get(locator, "contact.phone") && (
												<p>
													<a href={`tel:${get(locator, "contact.phone")}`}>
														{get(locator, "contact.phone")}
													</a>
													<br></br>
												</p>
											)}
											{get(locator, "contact.website") && (
												<a
													href={get(locator, "contact.website", "").replace(
														/^www./gm,
														"http://www."
													)}
												>
													{get(locator, "contact.website")}
												</a>
											)}
											<br />
											<br />
											{hasPolicy && (
												<button
													className="btn-ss"
													onClick={() => props.setContactLocator(locator)}
												>
													Contact
												</button>
											)}
										</div>
									</InfoWindowF>
								)}
							</MarkerF>
						</animated.div>
					);
				})}
		</>
	);
};

export default Markers;
