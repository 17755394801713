import * as React from "react";

import { FormControlLabel, Checkbox } from "@mui/material";
// import { IconButton } from "material-ui";
type T = {
	onChange: (cheked: boolean) => void;
	icon: React.ReactElement;
	label: string;
	value: boolean;
	checked?: boolean;
};
const FilterCheck: React.FC<T> = ({
	onChange,
	icon,
	label,
	value,
	checked,
}) => {
	// const dispatch = useDispatch();

	const FormLabel = (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				gap: "0.5em",
			}}
		>
			<span style={{ maxWidth: "30px" }}>{icon}</span>
			<span>{label}</span>
		</div>
	);

	return (
		<FormControlLabel
			control={
				<Checkbox
					sx={{
						color: "black",

						"&.Mui-checked": {
							color: "black",
						},
					}}
				/>
			}
			label={FormLabel}
			labelPlacement="end"
			onChange={(e, checked) => onChange(checked)}
			checked={value || false}
		/>
	);
};

export default FilterCheck;
